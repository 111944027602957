import GlobalStyles from './components/GlobalStyles'
import Cv from './components/Cv'
import Hero from './components/Hero'
import Projects from './components/Projects'
import Skills from './components/Skills'
import Footer from './components/Footer'
import Books from './components/Books'

function App() {
  return (
    <div>
      <GlobalStyles />
      <Hero />
      <Skills />
      <Projects />
      <Cv />
      <Books />
      <Footer />
    </div>
  )
}

export default App
