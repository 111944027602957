const exempelList = [
  {
    name: 'Svenska pudelklubben',
    img: '../img/spk.png',
    alt: 'screenshot of the site',
    description:
      'Utveckling av eget tema, migrering, utbildning av webbredaktörer.',
    tags: ['Figma', 'PHP', 'CSS', 'SQL', 'Wordpress'],
    demo: 'https://www.pudelklubben.se/',
    code: 'https://github.com/code-and-cats/svenska-pudelklubben-2024'
  },
  {
    name: 'Dynamic Duo',
    img: '../img/dd.png',
    alt: 'the site shown on desktop and mobile',
    description:
      'Konsultation kring innehåll och design för en företagssida för en eventfirma.',
    tags: ['Wordpress'],
    demo: 'http://dynamicduo.se/',
    code: 'http://dynamicduo.se/'
  },
  {
    name: 'Cirkelträningsapp',
    img: '../img/circleit.png',
    alt: 'screenshot of the site',
    description:
      'Slutprojekt på frontend-utbildning. En cirkelträningsapp byggd ihop med ett team.',
    tags: ['React', 'MongoDB', 'Redux', 'API', 'mob-programming'],
    demo: 'https://circle-it.netlify.app/',
    code: 'https://github.com/Cheroptera/circle-it-FE'
  }
]
export default exempelList
