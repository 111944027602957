import React from 'react'
import { styled } from 'styled-components'

const Cv = () => {
  return (
    <StyledSection>
      <Wrapper>
        <StyledH2>Tidigare arbetsgivare</StyledH2>
        <p>
          Kognic, Göteborgs Stad, Botkyrka kommun, Stiftarna av Independent
          Living i Sverige (STIL), Östhammars kommun, Familjebostäder, RFSL
          Ungdom
        </p>

        <a href="https://vioszabo.se/CV%20Vio%20Szabo.pdf">
          <Button>CV som PDF</Button>
        </a>
      </Wrapper>
    </StyledSection>
  )
}

export default Cv

const StyledSection = styled.section`
  background-color: #fcf5e8;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
`

const Wrapper = styled.div`
  width: 327px;
  margin: auto;
  align-content: center;

  @media (min-width: 744px) and (max-width: 1279px) {
    width: 500px;
  }

  @media (min-width: 1280px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 140px;
    width: 820px;
  }
`

const StyledH2 = styled.h2`
  font-size: 62px;
  color: #824c5b;
  margin-bottom: 2rem;
`

const Button = styled.button`
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding: 10px 30px;
  background: #137565;
  border-radius: 8px;
  border: 0px;
  color: white;

  &:hover {
    background: #824c5b;
  }
`
